<template>
  <div class="product-slider">
    <slot />

    <VueCarousel
      ref="carousel"
      v-bind="sliderSettings"
    >
      <VueSlide
        v-for="(product, index) in products"
        :key="product"
      >
        <ProductTile
          :product="product"
          :lazy="lazy || index > 2"
          class="product-slider__product"
          :show-cta="showCta"
        />
      </VueSlide>

      <template #addons>
        <div
          v-if="showNavigation"
          class="carousel__navigation"
        >
          <VueNavigation>
            <template #prev>
              <IconArrow class="carousel__icon icon icon--arrow icon--arrow-left" />
            </template>
            <template #next>
              <IconArrow class="carousel__icon icon icon--arrow icon--arrow-right" />
            </template>
          </VueNavigation>
        </div>
      </template>
    </VueCarousel>
  </div>
</template>

<script setup lang="ts">
import type { EsProduct } from '~/types/elastic'

const props = withDefaults(defineProps<{
  products: EsProduct[]
  lazy?: boolean
  showCta?: boolean
  customSettings?: any
}>(), {
  lazy: true,
  showCta: false,
  customSettings: {
    itemsToShow: 2,
    itemsToScroll: 1,
    snapAlign: 'start',
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3,
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
      },
    },
  },
})

const { settings } = useSliderSettings()
const sliderSettings = Object.assign({}, settings, props.customSettings)

const carousel = ref()
const showNavigation = computed(() => {
  return props.products?.length > carousel.value?.data?.config?.itemsToShow
  // return carousel.value?.data?.slidesCount.value > carousel.value?.data?.config?.itemsToShow
})

// const targetIsVisible = useElementVisibility(carousel)

// watchEffect(() => {
//   if (targetIsVisible.value) {
//     carousel.value.restartCarousel() // try to fix carousel when it was initially hidden
//   }
// })
</script>

<style src="~layers/app/assets/css/vue3-carousel-nuxt.css" />

<style src="~layers/app/components/Product/Slider.css" />
